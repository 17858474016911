import React from 'react';
import './App.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>© Managed and developed by Super Star Mahesh Babu Fans @VamsiM777__ & @urstruly_jithu.</p>
    </footer>
  );
};

export default Footer;
