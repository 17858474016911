import logo from './logo.svg';
import './App.css';
import Home from './Home';
import AnimatedText from './AnimatedText';
import Footer from './Footer';


function App() {
  return (
    <div className="App">
   <Home />
   <AnimatedText />
   <Footer />
  </div>
  );
}

export default App;
